import React from 'react'
// import { Link } from 'gatsby'
import loadable from '@loadable/component'
import Helmet from 'react-helmet'

//import config from '../../config'
import { navigate } from 'gatsby-link'
//import ReactDOM from 'react-dom'
import Mailgun from 'mailgun.js'
//import { oneDark } from '@codemirror/theme-one-dark';
//import CodeMirror from '@uiw/react-codemirror';
//import { javascript } from '@codemirror/lang-javascript';

import 'react-app-protect/dist/index.css'
//import renderHTML from 'react-render-html'



const Protect = loadable(() => import('react-app-protect'));

const API_KEY = process.env.REACT_APP_MG_API_KEY
const DOMAIN = process.env.REACT_APP_MG_DOMAIN

const formData = require('form-data');

const mailgun = new Mailgun(formData);
const client = mailgun.client({username: 'api', key: API_KEY});


// let htmlvalue = `<p>test data</p>`



async function getStats() {
    try {
      const statsForDomain = await client.stats.getDomain(DOMAIN,{ 'event': ['accepted', 'delivered', 'failed'], 'duration': '1m'})
      console.log('statsForDomain', statsForDomain.stats);
      document.getElementById('stats').innerHTML = JSON.stringify(statsForDomain.stats[0].accepted.total) + ' / 1000' ;
      document.getElementById('statsfull').innerHTML = JSON.stringify(statsForDomain.stats[0]) ;

    } catch (error) {
      console.error(error);
    }
}



function destroyInfo(){
  //console.log(global.localStorage.getItem('cipher'))
  global.localStorage.setItem('cipher','')
  navigate('/')
}


async function handleSubmit(){

  let messageData
  /*let fileinput = document.getElementById('fileinput');
  let file = fileinput.files[0];

  //disableEmptyInputs();

  if (file !== undefined){
      if (file.size < 1048576){
          let reader  = new FileReader();
          reader.readAsArrayBuffer(file)
          reader.onload = function(e) {
              const blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
              let attachment = blob
              console.log(attachment)


              messageData = {
                from: document.getElementById('from').value,
                to: document.getElementById('to').value,
                cc: document.getElementById('cc').value,
                bcc: document.getElementById('bcc').value,
                subject: document.getElementById('subject').value,
                html: document.getElementById('value-holder').value,
                attachment
              };
              console.log(messageData)
              client.messages.create(DOMAIN, messageData)
               .then((res) => {
                 console.log(res);
               })
               .catch((err) => {
                 console.error(err);
               });

               alert('Emaile zostały wysłane, za 5 sekund zaktualizują się statystyki!')
               setTimeout(getStats,5000)
          };

      }
    } else { */

       messageData = {
          from: document.getElementById('from').value,
          to: document.getElementById('to').value,
          cc: document.getElementById('cc').value,
          bcc: document.getElementById('bcc').value,
          subject: document.getElementById('subject').value,
          html: document.getElementById('mirror').value,
        };
        console.log(messageData)
        client.messages.create(DOMAIN, messageData)
         .then((res) => {
           console.log(res);
           document.getElementById('send-msg').innerHTML = JSON.stringify(res);
           alert('E-mails have been sent, statistics will update in 5 seconds!')
         })
         .catch((err) => {
           console.error(err);
           document.getElementById('send-msg').innerHTML = JSON.stringify(err);
         });
         setTimeout(getStats,5000)
      }







const EmailSender = () => {

  if (typeof window !== 'undefined') {
      console.log('we are running on the client')

  return(
  <>
  <Protect
  boxTitle="E-multicontent - this page is password protected."
  buttonLabel="Unlock"
  inputPlaceholder="enter password"
  sha512='0d40fd3e2ab9076d59f00e08ab061253e56837a7e85e263737fce228e5af874c5ed783f9be5be744fc01ca57d1106b13ab560ea24dc44d7ab8165a657ebf7f6f'>

  <Helmet
    htmlAttributes={{
      lang: `en-US`,
    }}
    title='Send mailing campaigns from the e-multicontent website'>
    <meta name="robots" content="noindex, nofollow" />
  </Helmet>


  <div style={{marginLeft:'10%',marginRight:'10%',padding:'5%'}}>
  <div id="action" style={{textAlign:'center',color:'#36b3d2',fontSize:'1.5em',width:'50%',marginLeft:'25%',marginTop:'50px',fontVariant:'all-petite-caps',letterSpacing: '0.3em'}}>
  <img src="/img/share-brief.svg" alt="Agencja copywriting e-multicontent" style={{maxWidth:'150px'}} />
  <h1 style={{fontWeight:'900',fontVariant:'small-caps',fontFamily:'system-ui',wordBreak:'break-word'}}>E-mail</h1>
  <hr style={{backgroundColor:'#36b3d2',marginTop:'0px'}} />
  </div>





  <div style={{textAlign:'center',display:'block',justifyContent:'center',flexWrap:'wrap'}}>
  <br />
  <code style={{maxWidth:'100%', wordBreak:'break-all',marginTop:'30px'}} id="statsfull"></code>
  <br />
  <p style={{color:'#36b3d2', textAlign:'center',marginTop:'30px', wordBreak:'break-all'}} id="stats"></p>
  <br />
  <sub style={{marginTop:'10px'}}>Total data from sending emails - autoresponder + email campaigns.</sub>
  </div>
  <br />





  <div style={{textAlign:'center',paddingBottom:'50px'}}>
    <button className='btnblog' onClick={getStats}> Update statistics </button>
  </div>
  <br />

  <h2 className="title" style={{textAlign:'center'}}>Send an email campaign:</h2>


<div id="top" style={{backgroundColor:'#f5f5f5',padding:'10px',borderRadius:'10px',backgroundSize:'cover',backgroundPosition:'top right',backgroundImage:'url(/img/formmsg.svg)'}}>
  <div className="field">
    <label className="label" htmlFor={'from'}>
      From<sup>*</sup>:
    </label>
    <div className="control">
      <input
        className="input"
        type={'text'}
        name={'from'}
        placeholder={'e-multicontent team <info@e-multicontent.com>'}
        id={'from'}
        required={true}
      />
    </div>
  </div>


  <div className="field">
    <label className="label" htmlFor={'subject'}>
      Subject<sup>*</sup>:
    </label>
    <div className="control">
      <input
        className="input"
        type={'text'}
        name={'subject'}
        id={'subject'}
        placeholder={'Newsletter - december 2023'}
        required={true}
      />
    </div>
  </div>

  <div className="field column">
    <label className="label" htmlFor={'to'}>
      The list of recipients<sup>*</sup>:&nbsp;<span id="num"></span>
    </label>
    <div className="control">
      <textarea
        className="textarea"
        type={'text'}
        name={'odbiorcy'}
        placeholder={'foo@example.com, bar@example.com,'}
        id={'to'}
        onChange={() => {
          let arr = document.getElementById('to').value.split(',')
          //console.log(arr.length)
          document.getElementById('num').innerText = arr.length-1
          sumEmails()
        }}
        required={true}
        rows = "3"
      ></textarea>
    </div>
    </div>

<div className="columns">
    <div className="field column">
      <label className="label" htmlFor={'cc'}>
        CC<sup>*</sup>:&nbsp;<span id="num2"></span>
      </label>
      <div className="control">
        <textarea
          className="textarea"
          type={'text'}
          name={'cc'}
          placeholder={'info@e-multicontent.com, foo@example.com,'}
          id={'cc'}
          onChange={() => {
            let arr = document.getElementById('cc').value.split(',')
            //console.log(arr.length)
            document.getElementById('num2').innerText = arr.length-1
            sumEmails()
          }}
          required={false}
          rows = "3"
        ></textarea>
      </div>
    </div>

    <div className="field column">
      <label className="label" htmlFor={'bcc'}>
        BCC<sup>*</sup>:&nbsp;<span id="num3"></span>
      </label>
      <div className="control">
        <textarea
          className="textarea"
          type={'text'}
          name={'bcc'}
          placeholder={'info@e-multicontent.com, foo@example.com,'}
          id={'bcc'}
          onChange={() => {
            let arr = document.getElementById('bcc').value.split(',')
            //console.log(arr.length)
            document.getElementById('num3').innerText = arr.length-1
            sumEmails()
          }}
          required={false}
          rows = "3"
        ></textarea>
      </div>
    </div>
</div>
</div>
<hr />
<p><sup>*</sup>Recipient lists must end with a comma.</p>

<p style={{textAlign:'center'}} id="suma"></p>

<div className="columns">
<div className="column">
<br />
<hr />
<label className="label" htmlFor={'mirror'} style={{color:'#36b3d2'}}>Email body element (HTML):</label>
  <textarea
    className="textarea"
    type={'text'}
    id="mirror"
    name={'body'}
    height="500px"
    required={true}
    rows = "20"
  ></textarea>
  {/*}<CodeMirror
      value={htmlvalue}
      height="500px"
      theme={oneDark}
      id="mirror"
      maxWidth="500px"
      extensions={[javascript({ jsx: true })]}
      onChange={(value, viewUpdate) => {
        htmlvalue = value
        document.getElementById('value-holder').value = value;
        renderPrev()
      }}
    />*/}
<br />
<br />
<div style={{textAlign:'center',paddingBottom:'20px'}}>
{/*<button className='btnblog' onClick={loadSample}> załaduj przykładowy content </button> &nbsp;&nbsp;*/}
  <br /><br />
  <code style={{maxWidth:'100%', wordBreak:'break-all',marginTop:'30px'}} id="send-msg"></code>
</div>

<div style={{textAlign:'center',paddingBottom:'50px'}}>

  {/*<div className="field ">
  <div className='control'>
  <label className='label is-size-10'>Załącz plik:</label>
    <div className="file">
      <label className="file-label" style={{width:'100%',cursor:'pointer'}}>
        <input
          className="custom-file-input"
          type="file"
          name="plik"
          style={{width:'100%',cursor:'pointer'}}
          id ="fileinput"
        />
      </label>
    </div>
    <br />
    <p align="center" style={{backgroundColor:'#111111',color:'white',padding:'2px',fontSize:'12px'}}> Maksymalna wielkość pliku to <b>1MB</b>. </p>
  </div>
  </div> */}
</div>
</div>
<div className="column">
<br />
<hr />
{/*<span className="label" style={{color:'#36b3d2'}}>Podgląd emaila:</span>
<div style={{width:'100%',height:'max-content',border:'3px solid #36b3d2',padding:'10px',borderRadius:'10px'}} id="prev"></div>*/}
<p style={{textAlign:'center'}}>  <button className='btnblog' style={{marginTop:'55px'}} type="submit" onClick={handleSubmit}> Send an email campaign </button></p>
</div>
</div>

<div style={{textAlign:'center',paddingBottom:'20px'}}>
  <button className='btnblog' onClick={destroyInfo}> Close the mailing service</button>
</div>
<br />
</div>


  </Protect>
  </>
)} else {
    console.log('we are running on the server');
    return(
      <>
      <p>...</p>
      </>
    )
}
}

export default EmailSender

/*
function renderPrev() {
    ReactDOM.render(renderHTML(document.getElementById('mirror').value), document.getElementById('prev'));
}
function loadSample(){
  document.getElementById('mirror').value = htmlvalue
  renderPrev()
}*/

function sumEmails(){
  //console.log(Number(document.getElementById('num').innerText),document.getElementById('num2').innerText,document.getElementById('num3').innerText)
  document.getElementById('suma').innerHTML = 'Total number of e-mails to be sent: '+(Number(document.getElementById('num').innerText) + Number(document.getElementById('num2').innerText) + Number(document.getElementById('num3').innerText))
}
